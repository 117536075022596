<script lang="ts">
import { h } from "vue";

export default defineComponent({
  functional: true,
  setup() {
    const requestURL = useRequestURL();
    const canonical = computed(() => {
      let host = requestURL.host;
      const { itemKey } = useInjectionKeys();
      const item = inject(itemKey);
      let region;
      if (item?.attributes?.region?.data)
        region = item?.attributes?.region?.data;
      else if (item?.attributes?.places?.data[0]?.attributes?.region?.data)
        region = item?.attributes?.places?.data[0]?.attributes?.region?.data;
      else region = null;

      log(`Canonical Region:`, region);

      if (region) {
        host = region.attributes.url;
      }
      //repace http with https
      if (process.env.NODE_ENV === "production") {
        host = host.replace("http://", "https://");
      }

      if (!host.startsWith("https://")) {
        host = `https://${host}`;
      }

      return `${host}${requestURL.pathname}`;
    });

    useHead(() => {
      return {
        link: [
          {
            rel: "canonical",
            href: canonical,
          },
        ],
      };
    });
  },
  render() {
    return h("div", null);
  },
});
</script>
